import React from 'react';
import './css/About.css';

const About: React.FC = () => {
  return (
<div className="about-section">
  <h1>About Star(T) Project</h1>
  <p>
    Welcome to the Star(T) Project! 🌟✨ We are on a mission to revolutionize the NFT space with unparalleled creativity and a vibrant community. Our project is all about transforming stellar inspirations into unique digital artworks, from star-shaped trees to cosmic castles. 🌌🌳🏰
  </p>
  <p>
    Star(T) is not just an NFT project; it’s a cosmic journey where art meets technology. 🚀 Our community-driven platform allows artists and enthusiasts to create, color, and trade NFTs, making the universe of digital art more accessible and exciting. 🎨🌠
  </p>
  <p>
    Join us and be a part of this stellar adventure. Participate in our contests, color your favorite NFTs, and stand a chance to win amazing rewards! 🏆✨ With every NFT you own, you contribute to the growth of our ecosystem, supporting social media campaigns, website maintenance, contests, and the continuous development of the project. 📈🌐
  </p>
  <p>
    Let's paint the stars together and bring the beauty of the cosmos to life in the digital realm. Your creativity is the key to unlocking the infinite possibilities of the Star(T) universe. 🌠🔑
  </p>
</div>


  );
};

export default About;
