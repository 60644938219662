import React from 'react';
import './css/ProjectPlan.css';

const milestones = [
  { date: 'July 1, 2024', event: 'First Pre-Sale' },
  { date: 'July 16, 2024', event: 'Public Sale Stage' },
  { date: 'August 31, 2024', event: 'First Contest Results Announcement' },
  { date: 'September 2024', event: 'Second Pre-Sale' },
  { date: 'October 2024', event: 'Creation of NFT Coloring Tool' },
  { date: 'November 2024', event: 'Wallet Integration' },
  { date: 'January 2025', event: 'Launch of Star(T) Coin' },
  { date: 'April 2025', event: 'Coloring to Earn Coins' },
  { date: 'Mid 2025', event: 'Additional NFT Sales' },
  { date: 'July 2025', event: 'Second Contest Results Announcement' }
];

const currentMilestoneIndex = -1; // Example index for current milestone

const ProjectPlan: React.FC = () => {
  return (
    <div className="project-plan-content">
      <h1>Project Plan</h1>
      <div className="horizontal-scroll">
        {milestones.map((milestone, index) => (
          <div
            key={index}
            className={`milestone-card ${
              index === currentMilestoneIndex ? 'current' : ''
            }`}
          >
            <div className="date">{milestone.date}</div>
            <div className="event">{milestone.event}</div>
          </div>
        ))}
      </div>
      <div className="progress-bar-container">
        {milestones.map((_, index) => (
          <div
            key={index}
            className={`progress-bar-segment ${
              index <= currentMilestoneIndex ? 'active' : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectPlan;
