import React from 'react';
import './css/Contact.css';

const Contact: React.FC = () => {
  return (
    <div className="contact-section">
      <h1>Contact Us</h1>
      <p>We would love to hear from you! Feel free to reach out to us with any questions, feedback, or collaboration ideas.</p>
      <form className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required></textarea>
        </div>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
      <p>Or you can email us directly at: <a href="mailto:contact@startprojectnft.com">contact@startprojectnft.com</a></p>
    </div>
  );
};

export default Contact;
