import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import './css/Home.css';

// Rejestracja pluginu
Chart.register(ChartDataLabels);

const Home: React.FC = () => {
  const targetDate = new Date('2024-07-01T16:00:00').getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;
    return difference > 0 ? difference : 0;
  };

  const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const { days, hours, minutes, seconds } = getTimeComponents(timeLeft);

  const formatTime = (value: number): string => {
    return value.toString().padStart(2, '0');
  };

  function getTimeComponents(n: number) {
    const totalSeconds = Math.floor(n / 1000);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;
    return { days, hours, minutes, seconds };
  }

  const renderTimeSegment = (label: string, value: number) => {
    return (
      <div className="time-segment">
        <div>
          {formatTime(value)}
        </div>
        {label}
      </div>
    );
  };

  const data: ChartData<'pie'> = {
    labels: ['Social\nMedia', 'Project\nMaintenance', 'Contests', 'Project\nDevelopment', 'Promotion'],
    datasets: [
      {
        data: [15, 30, 10, 25, 20],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        borderColor: '#000000', // Adding black border for visibility
        borderWidth: 2,
      },
    ],
  };

  const options: ChartOptions<'pie'> = {
    cutout: '50%', // Środek wykresu pusty
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Ukryj legendę
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}%`,
        },
      },
      datalabels: {
        display: true,
        color: '#FBFF5C', // Ustawienie koloru czcionki na biały
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Ustawienie tła etykiet
        borderRadius: 3,
        padding: 6,
        font: {
          size: 14,
          weight: 'bold',
        },
        formatter: (value, context) => {
          const label = context.chart.data.labels
            ? context.chart.data.labels[context.dataIndex]
            : '';
          return `${label}`;
        },
        textAlign: 'center',
        clip: true,
      },
    },
  };

  return (
    <div className='home-content'>
      <div className="welcome-section">
        <h1>Welcome to Star(T) Project</h1>
        <p>Join us in revolutionizing the NFT space with innovative creativity and a strong community. 🚀✨</p>
      </div>


      <div className="countdown-section">
        <h2>Start Pre-Sale</h2>
        <div className="countdown">
          {renderTimeSegment('', days)}:
          {renderTimeSegment('', hours)}:
          {renderTimeSegment('', minutes)}:
          {renderTimeSegment('', seconds)}
        </div>
      </div>

      <div className="fund-allocation-section">
        <h2>Fund Allocation</h2>
        <div className="pie-chart">
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Home;
