import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Home from './webs/Home';
import About from './webs/About';
import ProjectPlan from './webs/ProjectPlan';
import Contact from './webs/Contact'; // Import the Contact component

const App: React.FC = () => {
  return (
    <Router>
      <div className="background">
        <div className="stars" id="stars1"></div>
        <div className="stars" id="stars2"></div>
        <div className="stars" id="stars3"></div>
      </div>
      <header>
        <div className="options">
          <Link to="/" className="Block-Select options-tile">
            <img className="Logo-Text" src="/static/svg/Logo.svg" alt="Logo" />
          </Link>
          {/*<a href="#" className="Block-Select options-tile">
            <i className="fa-solid fa-gear"></i>
        </a>*/}
        </div>
        <div className="social">
          <a href="https://discord.gg/5PTBpNd88x" className="social-tile" target="_blank">
            <i className="fab fa-discord"></i>
          </a>
          <a href="https://www.instagram.com/StarTProjectNFT/" className="social-tile" target="_blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.x.com/StarTProjectNFT/" className="social-tile" target="_blank">
            <i className="fa-brands fa-x-twitter"></i>
          </a>
        </div>
      </header>
      
      <div className="floating-block">
        <div className="content" id="floatingContent">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/project-plan" element={<ProjectPlan />} />
            <Route path="/contact" element={<Contact />} /> {/* Add the Contact route */}
            <Route path="*" element={<Home />} /> {/* Catch-all route for 404 */}
          </Routes>
        </div>
      </div>

      <footer className="dock">
      <Link to="/" className="dock-item">
          <i className="fa-solid fa-house"></i>
          <span>Home</span>
        </Link>
        <Link to="/about" className="dock-item">
          <i className="fa-solid fa-info-circle"></i>
          <span>About Us</span>
        </Link>
        <Link to="/project-plan" className="dock-item">
          <i className="fa-solid fa-project-diagram"></i>
          <span>Project Plan</span>
        </Link>
        <Link to="/contact" className="dock-item">
          <i className="fa-solid fa-envelope"></i>
          <span>Contact</span>
        </Link>
      </footer>
    </Router>
  );
};

export default App;
